import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { id: "about" }
const _hoisted_2 = { class: "right" }
const _hoisted_3 = {
  key: 0,
  class: "top_title"
}
const _hoisted_4 = ["innerHTML"]

import TypeList from '@/components/TypeList.vue';
  import { onMounted, ref, watch, getCurrentInstance } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { CONST } from '@/types/const'
  import CommonTable from '@/components/CommonTable.vue';
  import { useStore } from 'vuex';
  
export default /*@__PURE__*/_defineComponent({
  ...{
    components: {
      TypeList,
      CommonTable
    }
  },
  __name: 'Index',
  setup(__props) {

  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);

  

  let route = useRoute()
  let router = useRouter()
  let html = ref('')
  let title = ref('')
  let tableData = ref([])
  const store = useStore()
  let titleName = ref('')

  watch(() => route.meta.index, (n, o) => {
    if(n && n!= o) {
      showPage()
    }
  }, {
    immediate: true
  })

  function showPage() {
    globalProperties.$API[(route.meta as any).api]({
      type: route.meta.index
    }).then((res: any) => {
      title.value = res.data.data.title
      html.value = res.data.data.mainText;
      tableData.value = res.data.data.attachmentList || []
      titleName.value = `${ res.data.data.title } - ${ store.state.commoInfo.websiteName }`
    })
  }

  function eventClick(link: any) {
    globalProperties.$API.GetUserInfo().then((res: any) => {
      if(res.data.code == 200) {
        router.push(link)
      }else {
        globalProperties.$message.warning('您还未登录或者登录已失效，请登录再操作此按钮！')
      }
    })
    
  }

  function downLoadClick(item: any) {
    window.location = globalProperties.$API.DownloadFile({
      fileName: item.fileName,
      path: item.url
    })
  }
 
return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _directive_wechat_title = _resolveDirective("wechat-title")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TypeList, {
      list: (_unref(CONST).commonType as any)[_ctx.$route.meta.type as any],
      keyName: (_ctx.$route.name as any)
    }, null, 8, ["list", "keyName"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$route.meta.nav != 'about')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h3", null, _toDisplayString(_unref(title)), 1),
            ((_unref(CONST).commonType as any)[_ctx.$route.meta.type as any][0].btn)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  type: "primary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (eventClick((_unref(CONST).commonType as any)[_ctx.$route.meta.type as any][0].link)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString((_unref(CONST).commonType as any)[_ctx.$route.meta.type as any][0].btn), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.$route.meta.nav != 'about')
        ? (_openBlock(), _createBlock(_component_a_divider, { key: 1 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "w-e-text",
        innerHTML: _unref(html)
      }, null, 8, _hoisted_4),
      (_unref(tableData).length > 0)
        ? (_openBlock(), _createBlock(CommonTable, {
            key: 2,
            columns: (_unref(CONST) as any).commonFileTable,
            tableData: _unref(tableData)
          }, {
            options: _withCtx(({ record }) => [
              _createVNode(_component_a_button, {
                type: "text",
                onClick: ($event: any) => (downLoadClick(record))
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("下载")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          }, 8, ["columns", "tableData"]))
        : _createCommentVNode("", true)
    ])
  ])), [
    [_directive_wechat_title, _unref(titleName)]
  ])
}
}

})